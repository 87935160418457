<template>
  <div
    v-if="showLoader"
    class="bg-neutral-50/30 backdrop-blur-[3px] absolute top-0 left-0 w-screen h-screen z-[3000]"
    data-cy="global_loader"
    @click="clickAway"
  >
    <div class="h-full flex justify-center items-center">
      <fwb-spinner class="!w-20 !h-20 !filter-none fill-primary-100" />
    </div>
  </div>
</template>
<script setup>
import { FwbSpinner } from 'flowbite-vue'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const showLoader = ref(false)

const loading = computed(() => store.state.loading.loading)
const stopLoader = () => store.dispatch('loading/stopLoader')

function clickAway() {
  stopLoader()
  showLoader.value = false
}

watch(
  () => loading.value,
  (value) => {
    showLoader.value = value
  }
)
</script>
