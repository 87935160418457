<template>
  <Modal :show-modal="showSelectFolderModal" @close="toggleSelectFolderModal">
    <template #header>
      <TitleSmall :title="$t('folders.select.title')" />
    </template>
    <template #body>
      <div class="flex flex-col gap-2">
        <div
          v-for="(folder, index) in allFolders"
          :key="index"
          class="cursor-pointer flex items-center gap-2 rounded border border-neutral-80 p-2 hover:border-primary-100"
          @click="selectFolder(folder)"
        >
          <i class="fas fa-folder text-primary-100 text-3xl" />
          <TextRegular bold>
            {{ folder.attributes.name }}
          </TextRegular>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script setup>
import TextRegular from '@/components/shared/font/text/TextRegular'
import TitleSmall from '@/components/shared/font/title/TitleSmall'
import Modal from '@/components/shared/Modal'
import i18n from '@/i18n'
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const projectId = computed(() => store.state.workspace.projectId)
const allFolders = computed(() => store.state.folder.allFolders)
const showSelectFolderModal = computed(
  () => store.state.modals.showSelectFolderModal
)

const addProjectToFolder = (payload) =>
  store.dispatch('workspace/addProjectToFolder', payload)
const toggleSelectFolderModal = () =>
  store.commit('modals/toggleSelectFolderModal')

async function selectFolder(folder) {
  let data = {
    projectId: projectId.value,
    folderName: folder.attributes.name,
    folderId: folder.id
  }
  await addProjectToFolder(data)
  toggleSelectFolderModal()
}
</script>
