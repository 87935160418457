<template>
  <label
    :for="id"
    class="text-neutral-200 text-sm block"
    @click="emit('check')"
  >
    <input
      :id="id"
      type="radio"
      :disabled="disabled"
      :checked="active"
      :name="name"
      class="bg-neutral-50 border-neutral-80 text-primary-100 focus:ring-transparent"
    />
    {{ label }}
    <slot />
  </label>
</template>
<script setup>
import { ref } from 'vue'
const emit = defineEmits(['check'])
const props = defineProps({
  id: String,
  label: {
    type: String,
    default: ''
  },
  active: {
    type: Boolean,
    default: false
  },
  name: {
    type: String,
    default: 'method'
  },
  disabled: Boolean
})

const checked = ref(props.active)
</script>
