<template>
  <Modal :show-modal="showInviteUserModal" @close="toggleInviteUserModal">
    <template #header>
      <div class="flex flex-col items-center justify-center pt-7">
        <h2 class="font-semibold text-black text-xl">
          {{ $t('customer.users_permissions.modal.title') }}
        </h2>
        <span class="block text-neutral-200 text-sm">{{
          $t('customer.users_permissions.modal.text')
        }}</span>
      </div>
    </template>
    <template #body>
      <div>
        <InputFieldEmail
          id="user_email_input"
          :placeholder="$t('shared.label.email')"
          @inputChange="handleEmail"
        />
        <p v-if="userIsInvited" class="text-sm text-error-100 mt-1">
          {{ $t('shared.label.user_is_invited') }}
        </p>
      </div>
    </template>
    <template #footer>
      <div class="mt-5 flex flex-col">
        <BtnPrimary
          :disabled="disableButton"
          :title="$t('customer.users_permissions.modal.btn.send')"
          @click="onSend(email)"
        />
        <BtnSecondary
          :title="$t('shared.button.close')"
          @click="toggleInviteUserModal"
        />
      </div>
    </template>
  </Modal>
</template>
<script setup>
import Modal from '@/components/shared/Modal'
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import InputFieldEmail from '@/components/shared/input/InputFieldEmail'
import { validateEmail } from '@/helpers/validation'
import i18n from '@/i18n'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const email = ref('')

const showInviteUserModal = computed(
  () => store.state.modals.showInviteUserModal
)
const usersInvited = computed(() => store.state.invitations.usersInvited)

const userIsInvited = computed(() => {
  if (!usersInvited.value) {
    return []
  }
  const isInvited = usersInvited.value.find(
    (u) => u.attributes.email === email.value
  )
  return isInvited
})
const disableButton = computed(() => {
  if (userIsInvited.value !== undefined) return true
  if (validateEmail(email.value)) return false
  return true
})

const sendInvitation = (payload) =>
  store.dispatch('invitations/sendInvitation', payload)
const toggleInviteUserModal = () => store.commit('modals/toggleInviteUserModal')

function onSend(userEmail) {
  sendInvitation({ email: userEmail })
  email.value = ''
  toggleInviteUserModal()
}

function handleEmail({ value }) {
  email.value = value
}
</script>
