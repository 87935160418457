<template>
  <div
    class="rangeContainer relative bg-neutral-50 h-[5px] rounded-[15px] w-full"
    :class="{ '!cursor-default !opacity-50': !breakdowns && disabled }"
  >
    <input
      :key="updateKey"
      v-model="rangeValue"
      :disabled="disabled"
      class="absolute left-0 top-0 overflow-hidden opacity-0 h-[5px] !p-0 w-full cursor-pointer bg-neutral-50 outline-none transition-opacity duration-[0.2s] z-[2]"
      :class="{ '!cursor-default': !showHandler || disabled }"
      type="range"
      :min="min"
      :max="max"
      :step="step"
      :data-cy="id"
    />
    <div
      v-if="fillClassName"
      class="rangeSlider__fill absolute left-0 top-0 w-0 h-[4px] rounded-[5px]"
      :class="`${fillClassName ? ` ${fillClassName}` : ''} ${themes[fillColor]}`"
    />
    <div v-if="breakdowns" class="flex">
      <template v-for="(breakdown, key, index) in breakdowns.meta" :key="key">
        <div
          v-if="showBreakdown(key)"
          class="rangeSlider__fill absolute left-0 top-0 w-0 h-[4px] rounded-[5px]"
          :class="`bg-[${breakdownThemes[key]}]`"
          :style="getStyle(breakdown, index)"
        />
      </template>
    </div>
    <div
      v-else
      class="rangeSlider__fill absolute left-0 top-0 w-0 h-[4px] rounded-[5px]"
      :class="`${themes[fillColor]}`"
    />
    <div
      v-if="className"
      class="rangeSlider__handler absolute h-5 w-5 shadow border border-neutral-80 bg-white z-[1] rounded-full left-0 top-1/2 -translate-y-1/2"
      :class="`${className ? ` ${className}` : ''} ${
        showHandler ? '' : `!hidden`
      }`"
    />
    <div
      v-else
      class="rangeSlider__handler absolute h-5 w-5 shadow border border-neutral-80 bg-white z-[1] rounded-full left-0 top-1/2 -translate-y-1/2"
    />
  </div>
</template>
<script setup>
import {
  computed,
  getCurrentInstance,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  ref,
  watch
} from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const instance = getCurrentInstance()

const props = defineProps({
  max: Number,
  min: Number,
  value: Number,
  disabled: Boolean,
  updateKey: Number,
  id: String,
  step: {
    type: Number,
    default: 0
  },
  showHandler: {
    type: Boolean,
    default: true
  },
  fillColor: {
    type: String,
    default: 'primary'
  },
  breakdowns: {
    type: Object,
    default: null
  }
})

const emit = defineEmits(['updateValue'])

const rangeValue = ref(0)
const width = ref(0)
const className = ref('')
const fillClassName = ref('')
const themes = ref({
  primary: 'bg-primary-100',
  success: 'bg-success-200',
  error: 'bg-error-100'
})
const breakdownThemes = ref({
  machine_translation: '#6f7183',
  translation: '#010d66',
  content_generation: '#2c3fd5',
  ai_translation: '#9098da'
})

const showNav = computed(() => store.state.navigation.showNav)

watch(
  () => rangeValue.value,
  (value) => {
    let rangeFill = document.querySelector(
      `.${fillClassName.value ? fillClassName.value : 'rangeSlider__fill'}`
    )
    const percentage = Math.round((value * 100) / props.max)
    rangeFill.style.width = `${percentage}%`
    rangeSliderHandler()
    emit('updateValue', JSON.parse(value))
  }
)
watch(
  () => props.value,
  (value) => {
    rangeValue.value = JSON.parse(value)
  },
  { immediate: true }
)
watch(
  () => showNav.value,
  () => {
    rangeSliderHandler()
  }
)
watch(
  () => props.updateKey,
  () => {
    instance?.proxy?.$forceUpdate()
  }
)

onBeforeMount(() => {
  className.value =
    props.updateKey !== undefined
      ? `rangeSlider__handler${props.updateKey}`
      : ''
  fillClassName.value =
    props.updateKey !== undefined ? `rangeSlider__fill${props.updateKey}` : ''
})

onMounted(() => {
  window.addEventListener('load', rangeSliderHandler)
  window.addEventListener('resize', rangeSliderHandler)
})

onBeforeUnmount(() => {
  window.removeEventListener('load', rangeSliderHandler)
  window.removeEventListener('resize', rangeSliderHandler)
})

function rangeSliderHandler() {
  width.value = document.querySelector('.rangeContainer').clientWidth
  const percentage = Math.round((rangeValue.value * 100) / props.max) - 0.5
  let rangeHandler = document.querySelector(
    `.${className.value ? className.value : 'rangeSlider__handler'}`
  )
  rangeHandler.style.left = `${percentage}%`
}
function showBreakdown(key) {
  if (key != 'qa' && key != 'review') return true
}
function getStyle(breakdown, index) {
  let leftPosition = 0
  let max = 0
  if (props.breakdowns.usage >= props.breakdowns.max) {
    Object.values(props.breakdowns.meta).forEach((value) => {
      max += value
    })
  } else {
    max = props.breakdowns.max
  }
  for (let i = 0; i < index; i++) {
    const itemId = Object.keys(props.breakdowns.meta)[i]
    const itemWidth =
      Math.round((props.breakdowns.meta[itemId] * 100) / max) + 0.5
    leftPosition += parseInt(itemWidth)
  }
  const width = Math.round((breakdown * 100) / max)
  return {
    width: width > 0 ? `${width + 0.5}%` : '0',
    left: index === 2 ? '0' : leftPosition + '%'
  }
}
</script>
