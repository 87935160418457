<template>
  <Modal :show-modal="showRenameModal" @close="toggleRenameModal(false)">
    <template #header>
      <TitleRegular :title="$t('shared.button.rename_folder')" />
    </template>
    <template #body>
      <div class="flex flex-col">
        <input
          v-model="folder.name"
          :placeholder="$t('shared.placeholder.new_name')"
          class="border border-neutral-80 rounded-sm w-full hover:border-primary-100"
          :class="{ '!border-error-100': nameAlreadyExists }"
          type="text"
        />
      </div>
      <div v-if="nameAlreadyExists" class="text-xs mt-1 text-error-100">
        {{ $t('customer.folder.modal.name_exists') }}
        <font-awesome-icon
          icon="fa-solid fa-triangle-exclamation"
          class="text-error-100"
        />
      </div>
    </template>
    <template #footer>
      <BtnsWrapperVertical>
        <BtnPrimary
          :title="$t('shared.button.update')"
          class="w-full"
          :disabled="enableButton"
          @click="updateFolderName(folder)"
        />
        <BtnSecondary
          :title="$t('shared.button.close')"
          @click="toggleRenameModal(false)"
        />
      </BtnsWrapperVertical>
    </template>
  </Modal>
</template>
<script setup>
import Modal from '@/components/shared/Modal'
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapperVertical from '@/components/shared/btn/BtnsWrapperVertical'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import i18n from '@/i18n'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const folder = ref({
  id: '',
  name: ''
})

const allFolders = computed(() => store.state.folder.allFolders)
const renameFolder = computed(() => store.state.workspace.renameFolder)
const showRenameModal = computed(() => store.state.modals.showRenameModal)

const enableButton = computed(() => {
  return nameAlreadyExists.value || folder.value.name == null
})
const nameAlreadyExists = computed(() => {
  const foundName = allFolders.value.find(
    (obj) =>
      obj.attributes.name.toUpperCase() === folder.value.name.toUpperCase() &&
      obj.attributes.name.toUpperCase() != renameFolder.value.name.toUpperCase()
  )
  if (foundName) {
    return true
  } else return false
})

const toggleRenameModal = () => store.commit('modals/toggleRenameModal')
const updateFolderName = (payload) =>
  store.dispatch('workspace/updateFolderName', payload)

watch(
  () => showRenameModal.value,
  (isOpen) => {
    if (isOpen) {
      folder.value.id = renameFolder.value.id
      folder.value.name = renameFolder.value.name
    }
  }
)
</script>
