<template>
  <Modal
    v-if="allTiers && tier && featureKey && account"
    class="[&>.modal-wrapper]:max-w-[600px] [&>.modal-wrapper]:max-h-[97%]"
    :class="{
      '[&>.modal-wrapper]:desktop:max-w-[1250px]': plan !== 'enterprise',
      '[&>.modal-wrapper]:desktop:max-w-[600px]': upgradeSuccess
    }"
    :show-modal="showUpgradeSubscriptionModal"
    @close="handleCloseModalOutside"
    @keydown.escape="handleCloseModal"
  >
    <template
      v-if="!selectedSubscription && enableFreeTrial && annuallyChecked"
      #header
    >
      <CircleRocketAnimation
        class="displayNonde absolute z-[100] right-3 top-3 desktop:flex"
        :title="$t('customer.upgrade_sub.free_trial_anim.title')"
        :text="$t('customer.upgrade_sub.free_trial_anim.subtitle')"
        :bottom-note="$t('customer.upgrade_sub.free_trial_anim.info')"
      />
    </template>
    <template #body>
      <LocalLoader :is-small="true" :loading="loading" />
      <div class="flex flex-col items-center text-center mb-4">
        <template v-if="!selectedSubscription">
          <TextRegular>
            <i18n-t
              class="text-lg text-black font-bold mb-1 flex flex-wrap justify-center items-center"
              :keypath="title"
              tag="h3"
            >
              <template
                v-if="route.name == 'clientProject' && !navbarModal"
                #text
              >
                <h3 class="text-primary-100 text-lg px-1">
                  {{ $t('customer.upgarde_sub.word_limit') }}
                </h3>
              </template>
            </i18n-t>
          </TextRegular>
          <TextTiny v-if="route.name == 'clientProject' && !navbarModal">
            {{ $t('customer.upgrade_sub.subtitle') }}
          </TextTiny>
          <InfoTag
            v-else-if="enableFreeTrial && annuallyChecked"
            medium
            :text="$t('customer.upgrade_sub.payment.free_trial')"
          />
        </template>
        <template v-if="!upgradeSuccess && selectedSubscription">
          <TitleSmall :title="formTitle" />
          <TextTiny>
            {{ $t('customer.upgrade_sub.paymentInfo_subtitle') }}
          </TextTiny>
        </template>
        <template v-if="upgradeSuccess">
          <TitleSmall :title="$t('customer.upgrade_sub.success.title')" />
          <TextRegular class="mt-4">
            {{ $t('customer.upgrade_sub.success.text') }}
          </TextRegular>
        </template>
      </div>
      <div
        :class="{
          'flex flex-col justify-center items-center': !selectedSubscription,
          'grid grid-cols-1 desktop:grid-cols-2': selectedSubscription
        }"
      >
        <template v-if="!selectedSubscription">
          <div v-if="plan != 'enterprise'" class="flex gap-6 items-center">
            <RadioButton
              id="billed_annually"
              data-cy="billed_annually"
              :label="$t('customer.upgrade_sub.billed_annually')"
              :active="annuallyChecked"
              @check="handleBillingAnnually"
            />
            <RadioButton
              id="billed_monthly"
              data-cy="billed_monthly"
              :label="$t('customer.upgrade_sub.billed_monthly')"
              :active="monthlyChecked"
              @check="handleBillingMonthly"
            />
          </div>
          <SubscriptionCard
            v-if="plan === 'enterprise'"
            class="tabletLandscape:!w-[80%]"
            :subscription="subscriptions[2]"
          />
          <div
            v-else
            class="my-8 flex flex-col w-full items-center tabletLandscape:flex-row"
          >
            <SubscriptionCard
              v-for="(sub, i) in subscriptions"
              :key="i"
              :subscription="sub"
              :is-disabled="isDisabled"
              :period="period"
              :is-middle-item="true ? i == 1 : false"
              :focused="focusedSubscription.plan == sub.plan"
              @selectedSub="onSelectSub"
              @setFocus="setFocus"
            />
          </div>
        </template>
        <template v-if="!upgradeSuccess && selectedSubscription">
          <PaymentInfo
            :billing-data="billingData"
            :subscription="selectedSubscription"
            :disable-modal="disableModal"
            content="upgradeSub"
            @selectedSub="handleGoBack"
            @upgrade="setUpgarde"
            @disabledModal="setModal"
            @keydown.escape="handleCloseModal"
          />
          <PaymentOverview
            content="upgradeSub"
            :subscription="selectedSubscription"
          />
        </template>
      </div>
    </template>
    <template #footer>
      <BtnsWrapper
        v-if="!selectedSubscription"
        class="w-[40%] m-auto border-t border-neutral-80 flex justify-center"
      >
        <BtnSecondary
          :title="$t('shared.button.cancel')"
          type="button"
          @click="handleCloseModal"
        />
      </BtnsWrapper>
      <BtnsWrapper
        v-if="upgradeSuccess"
        class="flex w-full justify-end border-t-0"
      >
        <BtnPrimary
          :title="$t('shared.button.close')"
          type="button"
          data-cy="close_button"
          @click="handleCloseModal"
        />
      </BtnsWrapper>
    </template>
  </Modal>
</template>
<script setup>
import PaymentInfo from '@/components/payment/PaymentInfo'
import PaymentOverview from '@/components/payment/PaymentOverview'
import Modal from '@/components/shared/Modal'
import BtnPrimary from '@/components/shared/btn/BtnPrimary.vue'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapper from '@/components/shared/btn/BtnsWrapper'
import RadioButton from '@/components/shared/btn/RadioButton'
import TextRegular from '@/components/shared/font/text/TextRegular'
import TextTiny from '@/components/shared/font/text/TextTiny'
import TitleSmall from '@/components/shared/font/title/TitleSmall'
import LocalLoader from '@/components/shared/loaders/LocalLoader'
import CircleRocketAnimation from '@/components/subscription/CircleRocketAnimation.vue'
import SubscriptionCard from '@/components/subscription/SubscriptionCard.vue'
import InfoTag from '@/components/tags/InfoTag'
import i18n from '@/i18n'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
const store = useStore()
const route = useRoute()
const router = useRouter()
const $t = i18n.t

const annuallyChecked = ref(true)
const monthlyChecked = ref(false)
const disableModal = ref(false)
const featureKey = ref(null)
const tier = ref(null)
const selectedSubscription = ref(null)
const focusedSubscription = ref({})
const upgradeSuccess = ref(false)
const loading = ref(false)
const subscriptions = ref([
  {
    title: 'Startup',
    plan: 'startup',
    annually: '25',
    monthly: '50',
    words: 36000
  },
  {
    title: 'Scaleup',
    plan: 'scaleup',
    annually: '50',
    monthly: '100',
    words: 72000
  },
  {
    title: 'Enterprise',
    plan: 'enterprise',
    annually: $t('customer.upgrade_sub.custom_price'),
    monthly: $t('customer.upgrade_sub.custom_price')
  }
])
const enableFreeTrial = computed(() => store.getters['account/enableFreeTrial'])

const showUpgradeSubscriptionModal = computed(
  () => store.state.modals.showUpgradeSubscriptionModal
)
const subscription = computed(() => store.state.subscriptions.subscription)
const plan = computed(() => {
  return account.value?.attributes?.subscription?.attributes?.plan_identifier
})
watch(
  () => subscription.value,
  (sub) => {
    selectedSubscription.value = sub
  }
)

const billing = computed(() => store.state.payment.billing)
const allTiers = computed(() => store.state.account.allTiers)
const account = computed(() => store.state.account.account)

const navbarModal = computed(() => {
  if (route.params.upgradeUsage == 'true') return true
  return false
})

const title = computed(() => {
  if (route.name == 'clientProject' && !navbarModal.value)
    return 'customer.upgrade_sub.title'
  if (route.name == 'contentGenerationV2' && !navbarModal.value)
    return 'customer.upgrade_sub.title.caas'
  return 'customer.upgrade_sub.title.usage'
})
const isDisabled = computed(() => {
  if (
    account.value.attributes.subscription.attributes.plan_identifier ==
      'startup' ||
    account.value.attributes.subscription.attributes.plan_identifier ==
      'scaleup'
  ) {
    return true
  }
  return false
})
const billingData = computed(() => {
  return JSON.parse(JSON.stringify(billing.value))
})
const period = computed(() => {
  if (annuallyChecked.value) return 'annually'
  return 'monthly'
})
const formTitle = computed(() => {
  if (selectedSubscription.value) {
    return $t('customer.upgrade_sub.plan_get_started', {
      plan: selectedSubscription.value[0].title
    })
  }
  return ''
})

const toggleUpgradeSubscriptionModal = () =>
  store.commit('modals/toggleUpgradeSubscriptionModal')
const setSelectedSub = (payload) =>
  store.commit('subscriptions/setSelectedSub', payload)

onMounted(async () => {
  selectedSubscription.value = null
})
watch(
  () => billing.value,
  (bill) => {
    if (bill) {
      const tiers = bill.attributes.subscription.attributes.tiers
      featureKey.value = 'supplier_translated_words'
      tier.value = tiers[featureKey.value].tier
    }
  }
)

function handleBillingAnnually() {
  annuallyChecked.value = true
  monthlyChecked.value = false
}
function handleBillingMonthly() {
  monthlyChecked.value = true
  annuallyChecked.value = false
}

function handleGoBack() {
  if (subscription.value && !upgradeSuccess.value) {
    handleCloseModal()
  }
  selectedSubscription.value = null
}

function onSelectSub(value) {
  selectedSubscription.value = value
}
function setModal(value) {
  disableModal.value = value
}

function setFocus(value) {
  focusedSubscription.value = value
}

function setUpgarde(value) {
  upgradeSuccess.value = value
}

function handleCloseModalOutside() {
  if (disableModal.value) {
    return
  } else if (!upgradeSuccess.value) {
    handleCloseModal()
  }
}

function handleCloseModal() {
  selectedSubscription.value = null
  toggleUpgradeSubscriptionModal()
  router.replace({
    query: {
      ...route.query,
      upgradeSubscriptionStart: false
    },
    params: { upgradeUsage: false }
  })
  if (route.query.upgradeSubscriptionPaymentInfo) {
    router.replace({
      query: {
        ...route.query,
        upgradeSubscriptionStart: false,
        upgradeSubscriptionPaymentInfo: false
      }
    })
  }
  if (upgradeSuccess.value || route.query.upgradeSuccess) {
    router.replace({
      query: {
        ...route.query,
        upgradeSuccess: upgradeSuccess.value
      }
    })
    upgradeSuccess.value = false
    disableModal.value = false
  }
  setSelectedSub(null)
}
</script>
