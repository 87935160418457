<template v-once>
  <input
    ref="tagifyElement"
    class="rounded-sm bg-white border border-neutral-80 hover:border-primary-100 text-black block w-full text-sm p-2.5"
    :class="id"
    :value="value"
    :placeholder="placeholder"
  />
</template>
<script setup>
import DragSort from '@yaireo/dragsort'
import '@yaireo/dragsort/dist/dragsort.css'
import '@yaireo/tagify/dist/tagify.css'
import Tagify from '@yaireo/tagify/dist/tagify.min.js'
import { nextTick, onMounted, ref, watch } from 'vue'

const tagifyElement = ref()
const tagify = ref()

const props = defineProps({
  settings: Object,
  value: [String, Array],
  placeholder: String,
  id: String
})
watch(
  () => props.value,
  (newVal) => {
    tagify.value.loadOriginalValues(newVal)
  }
)

onMounted(() => {
  tagify.value = new Tagify(tagifyElement.value, props.settings)
  nextTick(() => {
    new DragSort(tagify.value.DOM.scope, {
      selector: '.' + tagify.value.settings.classNames.tag,
      callbacks: {
        dragEnd: () => tagify.value.updateValueByDOMTags
      }
    })
  })
})
</script>
<style lang="scss">
@use '../../../styles/colors' as *;
.over {
  transition: all 0.5s ease;
}

.tagify {
  --tag-bg: #ebf1fe;
  --tag-hover: #ebf1fe;
  --tag-text-color: #454a5b;

  --tag-inset-shadow-size: 2em;
  --tag--min-width: 1ch;
  --tag--max-width: auto;

  --tag-remove-bg: #ebf1fe;
  --tag-remove-btn-bg: #c9ccd8;
  --tag-remove-btn-bg--hover: #eb2f2f;
  --tag-remove-btn-color: white;
  --tag-hide-transition: 0.2s;
  --placeholder-color: #aaafc1;
  --tag-invalid-color: #6e6d7a;
  --tag-invalid-bg: #e1e4ea;

  font-size: 15px;
  border-color: #e6e7ec;
  border-radius: 2px;

  .tagify__tag__removeBtn {
    border-radius: 2px;
    margin-right: 4.66667px;
    margin-left: -4.66667px;
    line-height: 14px;
    font: 14px Serif;
    &:after {
      content: '\00D7';
      font-weight: 900;
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  .tagify__tag {
    display: inline-flex;
    margin: 4px 0 4px 4px;
    &--editable {
      .tagify__tag__removeBtn {
        --tag-remove-btn-bg: none;
        --tag-remove-btn-color: $black-100;
      }
    }
  }

  .tagify__input {
    padding: 0;
    margin: 0 0.25rem;
    margin-top: 2px;
  }

  &:not(.tagify--noTags) {
    .tagify__input::before {
      content: '';
    }
  }

  &.tagify--focus {
    border-color: $blue-80;
  }
}
</style>
