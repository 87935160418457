<template>
  <div
    class="grid grid-cols-[repeat(auto-fill,_minmax(15.625rem,_1fr))] auto-rows-auto grid-flow-dense gap-x-5 gap-y-5 pb-8 tabletPortrait:grid-cols-[repeat(auto-fill,_minmax(18.75rem,_1fr))]"
    :class="{ '!grid-cols-1 !gap-y-2.5': showListView }"
  >
    <div
      v-if="showListView && allFolders.length > 0"
      class="bg-neutral-80 grid text-sm grid-cols-[repeat(auto-fit,_minmax(180px,_1fr))] grid-rows-[auto] p-[10px_20px] rounded-md gap-x-5"
    >
      <span class="text-neutral-200 block">{{ $t('shared.label.name') }}:</span>
      <span class="text-neutral-200 block">{{ $t('shared.label.date') }}:</span>
      <span class="text-neutral-200 block"
        >{{ $t('shared.label.number_projects') }}:</span
      >
    </div>
    <slot />
  </div>
</template>
<script setup>
import i18n from '@/i18n'
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const showListView = computed(() => store.state.account.showListView)
const allFolders = computed(() => store.state.folder.allFolders)
</script>
