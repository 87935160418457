<template>
  <div class="flex itmes-center gap-2 cursor-pointer" @click="emit('back')">
    <button
      type="button"
      class="group py-1.5 px-2 bg-neutral-50 rounded-md text-sm hover:bg-neutral-80 hover:text-black"
      :class="{ 'bg-transparent hover:bg-neutral-80': noBg }"
    >
      <font-awesome-icon
        class="arrow-icon text-neutral-200 group-hover:text-black"
        icon="arrow-left"
      />
    </button>
    <slot />
  </div>
</template>
<script setup>
const emit = defineEmits(['back'])
const props = defineProps({
  noBg: Boolean
})
</script>
