<template>
  <Tooltip :label="$t('shared.button.create_project')">
    <span
      v-if="
        account.attributes && account.attributes.boolean_flags !== undefined
      "
      class="w-full rounded cursor-pointer flex justify-center items-center bg-white p-4 py-5 w-2.5 h-2.5 border border-neutral-80 shadow hover:bg-primary-100 [&>.fas]:hover:text-white"
      @click="showModal"
    >
      <i class="text-primary-100 fas fa-plus" />
    </span>
  </Tooltip>
</template>
<script setup>
import Tooltip from '@/components/shared/tooltip/Tooltip'
import i18n from '@/i18n'
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const account = computed(() => store.state.account.account)
const toggleProjectModal = () => store.commit('modals/toggleProjectModal')

async function showModal() {
  toggleProjectModal()
}
</script>
