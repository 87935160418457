<template>
  <Modal :show-modal="showMfaModal">
    <template #header>
      <TitleRegular :title="$t('shared.mfa.scan_title')" />
    </template>
    <template #body>
      <TextRegular v-if="mfa.qr_url">
        {{ $t('shared.mfa.scan_text') }}
      </TextRegular>
      <div class="flex gap-6 mt-6">
        <QrcodeVue
          v-if="mfa.qr_url"
          :value="mfa.qr_url"
          size="200"
          level="H"
          background="white"
          foreground="#2c3fd5"
        />
        <div v-if="mfa.recovery_codes">
          <TextRegular
            v-for="code in mfa.recovery_codes"
            :key="code"
            data-cy="recovery_code"
          >
            {{ code }}
          </TextRegular>
        </div>
      </div>
    </template>
    <template #footer>
      <BtnsWrapper class="flex flex-row justify-end">
        <BtnPrimary
          color="error"
          :title="$t('shared.button.close')"
          @click="handleClose"
          data-cy="close"
        />
      </BtnsWrapper>
    </template>
  </Modal>
</template>
<script setup>
import Modal from '@/components/shared/Modal'
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnsWrapper from '@/components/shared/btn/BtnsWrapper'
import TextRegular from '@/components/shared/font/text/TextRegular'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import i18n from '@/i18n'
import QrcodeVue from 'qrcode.vue'
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const mfa = computed(() => store.state.workspace.mfa)
const showMfaModal = computed(() => store.state.modals.showMfaModal)

const triggerMfaVerificationModal = () =>
  store.dispatch('modals/triggerMfaVerificationModal')
const clearMfa = () => store.commit('workspace/clearMfa')
const toggleMfaModal = () => store.commit('modals/toggleMfaModal')

function handleClose() {
  clearMfa()
  toggleMfaModal()
  triggerMfaVerificationModal()
}
</script>
