<template>
  <Modal :show-modal="showCreateTranslationModal" @close="onClose">
    <template #header>
      <div class="text-center">
        <TitleSmall :title="$t('customer.create_flow.title')" />
        <TextSmall>{{ $t('customer.create_flow.description') }}</TextSmall>
      </div>
    </template>
    <template #body>
      <div class="flex gap-4 flex-col">
        <div>
          <InputField
            data-cy="flow_name_input"
            :red-border="nameAlreadyExists"
            :placeholder="$t('customer.create_flow.name_placeholder')"
            @inputChange="getName"
          />
          <div v-if="nameAlreadyExists" class="text-xs mt-1 text-error-100">
            {{ $t('shared.label.worflow.name_exists') }}
            <font-awesome-icon
              icon="fa-solid fa-triangle-exclamation"
              color="red"
            />
          </div>
        </div>
        <Textarea
          data-cy="flow_description"
          :placeholder="$t('shared.placeholder.description_here')"
          @inputChange="getDescription"
        />
      </div>
    </template>
    <template #footer>
      <BtnsWrapperVertical>
        <BtnPrimary
          data-cy="create_flow"
          :title="$t('customer.create_flow.button')"
          class="w-full"
          :disabled="enableBtn"
          @click="onCreateNewFlow"
        />
        <BtnSecondary :title="$t('shared.button.close')" @click="onClose" />
      </BtnsWrapperVertical>
    </template>
  </Modal>
</template>
<script setup>
import Modal from '@/components/shared/Modal'
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapperVertical from '@/components/shared/btn/BtnsWrapperVertical'
import TextSmall from '@/components/shared/font/text/TextSmall'
import TitleSmall from '@/components/shared/font/title/TitleSmall'
import InputField from '@/components/shared/input/InputField'
import Textarea from '@/components/shared/textarea/Textarea'
import i18n from '@/i18n'
import useEmitter from '@/utils/emitter'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const route = useRoute()
const router = useRouter()
const $t = i18n.t
const { emit } = useEmitter()

const translation = ref({
  name: '',
  description: null
})

const currentRoute = computed(() => router.currentRoute.value.name)
const workflows = computed(() => store.state.automationFlows.workflows)
const showCreateTranslationModal = computed(
  () => store.state.modals.showCreateTranslationModal
)
const enableBtn = computed(
  () => nameAlreadyExists.value || translation.value.name == ''
)
const nameAlreadyExists = computed(() => {
  const foundName = workflows.value.find(
    (obj) =>
      obj.attributes.display_name.toUpperCase() ===
      translation.value.name.toUpperCase()
  )
  if (foundName) {
    return true
  } else return false
})

const createNewFlow = (payload) =>
  store.dispatch('automationFlows/createNewFlow', payload)
const toggleTranslationModal = () =>
  store.commit('modals/toggleTranslationModal')

function getName(name) {
  translation.value.name = name.value
}
function getDescription(value) {
  translation.value.description = value
}
function onCreateNewFlow() {
  if (currentRoute.value === 'clientPhraseConnectorStepTwo') {
    createNewFlow({
      name: translation.value.name,
      description: translation.value.description
    }).then(() => {
      emit('workflowCreated')
      onClose()
    })
    return
  }
  createNewFlow({
    name: translation.value.name,
    description: translation.value.description
  }).then(() => {
    onClose()
  })
}
function onClose() {
  translation.value = {
    name: '',
    description: null
  }
  toggleTranslationModal()
}
</script>
