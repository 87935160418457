<template>
  <fwb-button
    :id="id"
    :data-cy="id"
    :type="type"
    :disabled="isDisabled"
    :loading="loader"
    size="lg"
    class="justify-center rounded-sm focus:!ring-transparent"
    :class="[
      {
        '[&>div]:m-0': ($slots.default && !title) || loader,
        '!rounded-lg': rounded,
        '!bg-white !outline-primary-100 !text-primary-100 !border !border-primary-100 hover:!bg-primary-50':
          reversed
      },
      themes[color]
    ]"
  >
    {{ loader ? '' : title }}
    <template v-if="prefix" #prefix>
      <font-awesome-icon :icon="prefix" />
    </template>
    <template v-if="$slots.default" #suffix>
      <slot />
    </template>
  </fwb-button>
</template>
<script setup>
import { FwbButton } from 'flowbite-vue'
import { onMounted, ref, watch } from 'vue'
const props = defineProps({
  title: String,
  disabled: { type: Boolean, default: false },
  loader: { type: Boolean, default: false },
  type: String,
  reversed: {
    type: Boolean,
    default: false
  },
  id: String,
  color: {
    type: String,
    default: 'primary',
    validator(value, props) {
      // The value must match one of these strings
      return [
        'transparent',
        'primary',
        'lightPrimary',
        'success',
        'lightSuccess',
        'error',
        'lightError',
        'gradient'
      ].includes(value)
    }
  },
  rounded: Boolean,
  prefix: String
})
const isDisabled = ref(false)
const themes = ref({
  transparent: 'bg-transparent hover:bg-transparent',
  primary: 'bg-primary-100 hover:bg-primary-200',
  lightPrimary:
    '!text-black bg-primary-50 border border-primary-100 hover:bg-primary-80',
  success: 'bg-success-100 hover:bg-success-200',
  lightSuccess:
    '!text-black bg-success-50 border border-success-100 hover:bg-success-80',
  error: 'bg-error-100 hover:bg-error-200',
  lightError:
    '!text-black bg-error-50 border border-error-100 hover:bg-error-80',
  gradient:
    '!text-white hover:animate-[gradientEffect_100ms_1_linear_both_running] [&:not(:hover)]:animate-[gradientEffectReverse_100ms_1_linear_both_running] [&:not(:hover)]:!transition-all'
})
onMounted(() => {
  isDisabled.value = props.disabled
})
watch(
  () => props.disabled,
  (val) => {
    isDisabled.value = val
  }
)
</script>
