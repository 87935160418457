<template>
  <div class="relative w-full h-1 bg-neutral-50 rounded-md">
    <div
      :class="['absolute h-full rounded-md transition-all', strengthClass]"
      :style="{ width: `${strength}%` }"
    ></div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'

const props = defineProps({
  password: String
})

const passwordValue = ref(props.password)
const strength = ref(0)

const strengthClass = computed(() => {
  if (strength.value <= 25) return 'bg-error-100'
  if (strength.value <= 50) return 'bg-warning-100'
  if (strength.value <= 75) return 'bg-success-100'
  return 'bg-success-200'
})

watch(
  () => props.password,
  (newValue) => {
    passwordValue.value = newValue
    checkStrength()
  }
)

const checkStrength = () => {
  let calculatedStrength = 0
  const pass = passwordValue.value

  if (pass.length >= 6) calculatedStrength += 25
  if (/[A-Z]/.test(pass)) calculatedStrength += 25
  if (/[0-9]/.test(pass)) calculatedStrength += 25
  if (/[^A-Za-z0-9]/.test(pass)) calculatedStrength += 25

  strength.value = calculatedStrength
}
</script>
