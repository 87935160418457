<template>
  <Modal :show-modal="showG2ReviewModal">
    <template #header>
      <h4 v-if="currentUser.type == 'customer'" class="text-center text-black">
        <i18n-t keypath="customer.modal.fill_out_form" tag="span">
          <template #name>
            <strong
              ><a
                target="_blank"
                href="https://www.g2.com/products/easytranslate/reviews#reviews"
                >G2.com</a
              ></strong
            >
          </template>
        </i18n-t>
      </h4>
      <TextRegular v-else class="text-center text-black">
        <i18n-t keypath="customer.modal.fill_out_form_supplier" tag="span">
          <template #name>
            <strong
              ><a
                target="_blank"
                href="https://www.g2.com/products/easytranslate/reviews#reviews"
                >G2.com</a
              ></strong
            >
          </template>
        </i18n-t>
      </TextRegular>
    </template>
    <template #body>
      <iframe
        class="w-full h-[500px] mobileLandscape:min-w-fit mobileLandscape:w-[520px]"
        :src="`https://www.g2.com/partnerships/EasyTranslate/users/login.embed?state=${G2State}&email=${currentUserEmail}`"
      ></iframe>
    </template>
    <template #footer>
      <BtnsWrapperVertical class="!mt-0">
        <BtnSecondary
          :title="$t('shared.button.close')"
          @click="toggleG2ReviewModal"
        />
      </BtnsWrapperVertical>
    </template>
  </Modal>
</template>
<script setup>
import Modal from '@/components/shared/Modal'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapperVertical from '@/components/shared/btn/BtnsWrapperVertical'
import TextRegular from '@/components/shared/font/text/TextRegular'
import i18n from '@/i18n'

import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const G2State = computed(() => store.state.G2Review.G2State)
const currentUser = computed(() => store.state.workspace.currentUser)
const showG2ReviewModal = computed(() => store.state.modals.showG2ReviewModal)
const toggleG2ReviewModal = () => store.commit('modals/toggleG2ReviewModal')
const getG2State = () => store.dispatch('G2Review/getG2State')
const currentUserEmail = computed(() => {
  const encodedEmail = encodeURIComponent(currentUser.value.attributes.email)
  return encodedEmail
})

onMounted(async () => {
  await getG2State()
})
</script>
