<template>
  <div>
    <template v-if="isCustomer">
      <ModalCreateProject />
      <ModalCreateDocumentProject />
      <ModalExceededVoucher />
      <ModalCreateFolder />
      <ModalSelectFolder />
      <ModalRenameFolder />
      <ModalCreateTranslation />
      <ModalMultilingualProject />
      <ModalCaasIntro />
      <ModalUpgradeSubscription />
      <ModalRenameCaasDocument />
      <ModalEditCaasTemplate />
      <ModalDeleteCaasDocument />
      <ModalInviteUser />
      <ModalImportTemplate />
      <ModalAreaOfWork />
      <ModalAiIntro />
      <ModalCreateTerm />
    </template>
    <ModalReviewG2 />
    <ModalMfa />
    <ModalVerifyMfa />
    <ModalProjectInstructions />
    <template v-if="!isCustomer">
      <ModalCooperationAgreement />
    </template>
    <template v-if="isCustomer">
      <ModalAcceptTerms />
    </template>
  </div>
</template>
<script setup>
import ModalAcceptTerms from '@/components/shared/modals/ModalAcceptTerms'
import ModalAiIntro from '@/components/shared/modals/ModalAiIntro'
import ModalAreaOfWork from '@/components/shared/modals/ModalAreaOfWork.vue'
import ModalCaasIntro from '@/components/shared/modals/ModalCaasIntro'
import ModalCooperationAgreement from '@/components/shared/modals/ModalCooperationAgreement'
import ModalCreateDocumentProject from '@/components/shared/modals/ModalCreateDocumentProject'
import ModalCreateFolder from '@/components/shared/modals/ModalCreateFolder'
import ModalCreateProject from '@/components/shared/modals/ModalCreateProject'
import ModalCreateTerm from '@/components/shared/modals/ModalCreateTerm'
import ModalCreateTranslation from '@/components/shared/modals/ModalCreateTranslation'
import ModalDeleteCaasDocument from '@/components/shared/modals/ModalDeleteCaasDocument'
import ModalEditCaasTemplate from '@/components/shared/modals/ModalEditCaasTemplate'
import ModalExceededVoucher from '@/components/shared/modals/ModalExceededVoucher'
import ModalImportTemplate from '@/components/shared/modals/ModalImportTemplate'
import ModalInviteUser from '@/components/shared/modals/ModalInviteUser'
import ModalMfa from '@/components/shared/modals/ModalMfa'
import ModalMultilingualProject from '@/components/shared/modals/ModalMultilingualProject'
import ModalProjectInstructions from '@/components/shared/modals/ModalProjectInstructions'
import ModalRenameCaasDocument from '@/components/shared/modals/ModalRenameCaasDocument'
import ModalRenameFolder from '@/components/shared/modals/ModalRenameFolder'
import ModalReviewG2 from '@/components/shared/modals/ModalReviewG2.vue'
import ModalSelectFolder from '@/components/shared/modals/ModalSelectFolder'
import ModalUpgradeSubscription from '@/components/shared/modals/ModalUpgradeSubscription'
import ModalVerifyMfa from '@/components/shared/modals/ModalVerifyMfa'
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const currentUser = computed(() => store.state.workspace.currentUser)
const currentAccount = computed(() => store.state.workspace.currentAccount)

const isCustomer = computed(() => {
  return (
    currentAccount.value &&
    Object.values(currentAccount.value).length > 0 &&
    currentUser.value.type === 'customer'
  )
})
</script>
