<template>
  <div
    class="flex items-center text-white bg-primary-100 relative w-fit text-xs rounded text-ellipsis overflow-hidden break-words whitespace-break-spaces p-1 pr-[25px]"
    :class="{
      '!bg-primary-200': hover,
      '!bg-primary-50 !text-neutral-200 !border !border-primary-100': light,
      '!bg-primary-80 !text-neutral-200': light && hover,
      '!cursor-not-allowed !pr-[5px]': disabled
    }"
  >
    <slot />
    <button
      v-if="!disabled"
      data-cy="remove_filter_item"
      class="remove flex justify-center items-center absolute h-fit right-1 top-0.5 font-bold cursor-pointer"
      :class="{ '!text-neutral-200': light }"
      @click="$emit('remove')"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <i class="fas fa-times" />
    </button>
  </div>
</template>
<script setup>
import { ref } from 'vue'
const emit = defineEmits(['remove'])
const props = defineProps({
  light: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
})
const hover = ref(false)
</script>
