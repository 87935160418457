<template>
  <div
    class="header__inner__column relative cursor-pointer"
    @click="handleChat"
  >
    <i class="text-neutral-200 hover:text-primary-100 fas fa-comment-alt" />
    <span
      class="badge absolute -top-2.5 left-1.5 rounded-lg text-xs font-bold py-0.5 px-1.5 text-center bg-error-100 text-white"
      >{{ unread }}</span
    >
  </div>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const router = useRouter()

const props = defineProps({
  isCustomer: {
    type: Boolean,
    default: true
  }
})

const unread = ref(0)

const totalUnread = computed(() => store.getters['chat/totalUnread'])
const supplierTotalUnread = computed(
  () => store.getters['supplierChat/supplierTotalUnread']
)

watch(
  () => totalUnread.value,
  () => {
    updateUnread()
  }
)
watch(
  () => supplierTotalUnread.value,
  () => {
    updateUnread()
  }
)

onMounted(() => {
  updateUnread()
})

function handleChat() {
  if (props.isCustomer) {
    router.push({ name: 'customerChat' })
  } else {
    router.push({ name: 'supplierChat' })
  }
}
function updateUnread() {
  if (props.isCustomer) {
    unread.value = totalUnread.value
  } else {
    unread.value = supplierTotalUnread.value
  }
}
</script>
