<template>
  <Modal
    :show-modal="showAreaOfWorkModal"
    class="text-center [&>.modal-wrapper]:max-w-[800px] desktop:[&>.modal-wrapper]:w-[1250px]"
  >
    <template #header>
      <TitleSmall :title="$t('customer.area_of_work.modal.title')" />
      <TextSmall>
        {{ $t('customer.area_of_work.modal.description') }}
      </TextSmall>
    </template>
    <template #body>
      <div
        class="w-full grid grid-cols-[repeat(auto-fit,_minmax(200px,_1fr))] auto-rows-max grid-flow-dense gap-5"
      >
        <div
          v-for="area in areas"
          :key="area.title"
          class="w-full p-4 text-center rounded border border-neutral-80 bg-white cursor-pointer shadow hover:!bg-primary-50 hover:!border-primary-100"
          :class="{
            '!bg-primary-50 !border-primary-100':
              selectedItem === area.identifier
          }"
          @click="selectedItem = area.identifier"
        >
          <img
            class="h-[80px] w-[80px] m-auto"
            :src="require(`@/assets/illustrations/${area.img}.png`)"
          />
          <TextSmall bold>{{ area.title }}</TextSmall>
        </div>
      </div>
    </template>
    <template #footer>
      <BtnPrimary
        :title="$t('shared.button.next')"
        class="py-3 px-[60px] mt-4"
        :disabled="!selectedItem"
        @click="handleNext"
      />
    </template>
  </Modal>
</template>
<script setup>
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import TextSmall from '@/components/shared/font/text/TextSmall'
import TitleSmall from '@/components/shared/font/title/TitleSmall'
import Modal from '@/components/shared/Modal'
import i18n from '@/i18n'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const route = useRoute()
const $t = i18n.t

const selectedItem = ref('')
const areas = ref([
  {
    title: $t('customer.area_of_work.modal.marketing'),
    img: 'marketing',
    identifier: 'Marketing'
  },
  {
    title: $t('customer.area_of_work.modal.operations'),
    img: 'operations',
    identifier: 'Operations team'
  },
  {
    title: $t('customer.area_of_work.modal.product'),
    img: 'product',
    identifier: 'Product'
  },
  {
    title: $t('customer.area_of_work.modal.localisation'),
    img: 'localisation',
    identifier: 'Localisation team'
  },
  {
    title: $t('customer.area_of_work.modal.tech'),
    img: 'tech',
    identifier: 'Data/digitization'
  },
  {
    title: $t('customer.area_of_work.modal.other'),
    img: 'other',
    identifier: 'Other'
  }
])

const isAdmin = computed(() => store.getters['workspace/isAdmin'])
const showAreaOfWorkModal = computed(
  () => store.state.modals.showAreaOfWorkModal
)

const sendPlgGoalForm = (payload) =>
  store.dispatch('teams/sendPlgGoalForm', payload)
const toggleAreaOfWorkModal = () => store.commit('modals/toggleAreaOfWorkModal')
const toggleCaasAnimationModal = () =>
  store.commit('modals/toggleCaasAnimationModal')

function handleNext() {
  sendPlgGoalForm({ area_of_work: selectedItem.value })
  if (route.name === 'contentGenerationV2') {
    toggleCaasAnimationModal()
  }
  toggleAreaOfWorkModal()
}

onMounted(() => {
  selectedItem.value = ''
})

watch(
  () => showAreaOfWorkModal.value,
  (show) => {
    if (show && isAdmin.value) toggleAreaOfWorkModal()
  }
)
</script>
