<template>
  <fwb-spinner
    v-if="loading"
    class="!absolute inset-x-0 inset-y-0 m-auto z-[2001] !filter-none fill-primary-100"
    :class="size"
  />
</template>
<script setup>
import { FwbSpinner } from 'flowbite-vue'
import { computed } from 'vue'
const props = defineProps({
  loading: Boolean,
  isSmall: Boolean,
  isMedium: Boolean,
  isLarge: Boolean,
  isXLarge: Boolean
})
const size = computed(() => {
  if (props.isSmall) {
    return '!h-8 !w-8'
  } else if (props.isMedium) {
    return '!h-12 !w-12'
  } else if (props.isLarge) {
    return '!h-24 !w-24'
  } else {
    return '!h-36 !w-36'
  }
})
</script>
